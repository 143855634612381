.footerInPageLink {
    color: white;
}


@media screen and (max-width: 414px) {
        .entireFooter {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: black;
            color: white;
        }

        .footerContainer {
            display: flex;
            padding: 64px 16px;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            align-self: stretch;
        }

        .footerName {
            display: flex;
            padding: 6px 9px 7px 8px;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-family: 'Montserrat';
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 17px */
            letter-spacing: 0.51px;
            padding-bottom: 16px;
        }

        .footerLinks {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .footerInPageLink {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            color: #FFF;
            font-family: Verdana;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            padding-top: 16px;
            padding-bottom: 16px;
        }


        .footerInPageLink:hover {
            cursor: pointer;
            color: green;
            transition: 0.25s;
        }

        .footerAnchor {
            color: white;
            text-decoration: none;
            color: #FFF;
            text-align: center;
            font-family: Verdana;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 18.2px */
        }

        .footerAnchor:hover {
            cursor: pointer;
            color: green;
            transition: 0.25s;
        }

        .footerExternalLinks {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 40px;
            padding-top: 16px;
            padding-bottom: 16px;
            color: white;
        }

        .separator {
            width: 343px;
            height: 1px;
            background: rgba(228, 229, 218, 0.50);
        }

        .footerFooter {
            align-self: center;
            color: #E4E5DA;
            text-align: center;
            font-family: 'Montserrat';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 16px */
        }
}

@media screen and (min-width: 415px) and (max-width: 847px) {
    .entireFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        color: white;
    }

    .footerContainer {
        display: flex;
        padding: 64px 16px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-self: stretch;
    }

    .footerName {
        display: flex;
        padding: 6px 9px 7px 8px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-family: 'Montserrat';
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 17px */
        letter-spacing: 0.51px;
        padding-bottom: 16px;
    }

    .footerLinks {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footerInPageLink {
        color: #FFF;
        font-family: Verdana;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        padding-top: 16px;
        padding-bottom: 16px;
    }


    .footerInPageLink:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .footerAnchor {
        color: white;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
    }

    .footerAnchor:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .footerExternalLinks {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;
        padding-top: 16px;
        padding-bottom: 16px;
        color: white;
    }

    .separator {
        width: 343px;
        height: 1px;
        background: rgba(228, 229, 218, 0.50);
    }

    .footerFooter {
        align-self: center;
        color: #E4E5DA;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 16px */
    }
}

@media screen and (min-width: 848px) and (max-width: 1279px) {
    .entireFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
    }
    
    .footerContainer {
        display: flex;
        padding: 80px 160px;
        flex-direction: column;
        gap: 40px;
        width: 1120px;
    }
    
    .footerTopBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;
        width: 100%;
    }
    
    .footerName {
        display: flex;
        padding: 13px 5px 14px 6px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-family: 'Montserrat';
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 21px */
        letter-spacing: 0.63px;
    }
    
    .footerLinks {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    
    .footerInPageLinks {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        align-self: stretch;
        width: 154px;
        color: #FFF;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
    }
    
    .footerInPageLink:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    
    }
    
    .footerInPageLinksList {
        list-style: none;
        display: flex;
        gap: 40px;
    }
    
    .footerExternalLinks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;
        color: #FFF;
        text-align: center;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }
    
    .footerAnchor {
        color: white;
        text-decoration: none;
    }
    
    .separator {
        width: 100%;
        height: 1px;
        background: rgba(228, 229, 218, 0.50);
    }
    
    .footerFooter {
        align-self: center;
        color: #E4E5DA;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 16px */
    }
}

@media screen and (min-width: 1280px) {
    .entireFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
    }
    
    .footerContainer {
        display: flex;
        padding: 80px 160px;
        flex-direction: column;
        gap: 40px;
        width: 1120px;
    }
    
    .footerTopBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;
        width: 100%;
    }
    
    .footerName {
        display: flex;
        padding: 13px 5px 14px 6px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-family: 'Montserrat';
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 21px */
        letter-spacing: 0.63px;
    }
    
    .footerLinks {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    
    .footerInPageLinks {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        align-self: stretch;
        width: 154px;
        color: #FFF;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
    }
    
    .footerInPageLink:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    
    }
    
    .footerInPageLinksList {
        list-style: none;
        display: flex;
        gap: 40px;
    }
    
    .footerExternalLinks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;
        color: #FFF;
        text-align: center;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }
    
    .footerAnchor {
        color: white;
        text-decoration: none;
    }
    
    .separator {
        width: 100%;
        height: 1px;
        background: rgba(228, 229, 218, 0.50);
    }
    
    .footerFooter {
        align-self: center;
        color: #E4E5DA;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 16px */
    }
}