.errorMessage {
    color: #F00;
    font-family: "Verdana";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
}


@media screen and (max-width: 847px) {

    .entireContactBody {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98vw;
    }

    .contactBodyContainer {
        margin-top: 72px;
        display: flex;
        width: 100%;
        max-width: 340px;
        padding: 24px 16px 64px 16px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        align-self: stretch;
        height: 597px;
    }

    .contactHeader {
        align-self: center;
        color: var(--Text-Normal, #111);
        text-align: center;
        font-family: 'Montserrat';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24px */
    }

    .contactSubHeaderContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        align-self: stretch;
    }

    .contactSubHeaderTop {
        align-self: center;
        color: var(--Text-Normal, #111);
        text-align: center;
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 18px */
    }

    .contactSubHeaderBottom {
        align-self: center;
        color: #4B4B4B;
        text-align: center ;
        font-family: "Verdana";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 20.8px */
    }

    .contactPageAnchor {
        color: #279070;
        text-align: center;
        font-family: "Verdana";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 20.8px */
        text-decoration: none;
    }

    .contactFormContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
    }

    .contactEntireTopInputsContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        align-self: stretch;
    }

    .contactInputContainer {
        display: flex;
        width: 100%;
        max-width: 360px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    .contactInputLabel {
        color: var(--Text-Normal, #111);
        font-family: "Verdana";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 24px */
        align-self: flex-start;
    }

    .contactInputField {
        align-self: stretch;
        color: #000000;
        font-family: "Verdana";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 24px */
        border: none;
        outline: none;
    }

    .contactInputField::placeholder {
        color: #E4E5DA;
        opacity: 1;
    }

    .contactInputField:focus {
        outline: none

    }

    .contactInputSeperator {
        width: 100%;
        max-width: 360px;
        height: 1px;
        background: #111;
    }

    .contactMessageContainer {
        display: flex;
        width: 100%;
        max-width: 340px;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }

    .contactMessgageInputField {
        display: flex;
        height: 100px;
        width: 100%;
        color: #000000;
        font-family: "Verdana";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 24px */
        margin-top: -20px;
        resize: none;
        border: none;
        outline: none;
    }

    .contactMessgageInputField::placeholder {
        color: #E4E5DA;
        opacity: 1;
    }

    .contactMessageSeperator {
        width: 340px;
        height: 1px;
        background: #111;
        margin-top: -20px;
    }

    .sendButton {
        display: flex;
        height: 44px;
        width: 165px;
        padding: 15px 32px 15px 32px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: var(--Text-Normal, #111);
        color: #f0f0f0;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        border: none;
        margin-top: 40px;
    }

    .entireContactedBody {
        margin-top: 72px;
        display: flex;
        justify-content: center;
        width: 98vw;
        height: 50vh;
    }

    .contactedBodyContainer {
        display: flex;
        padding: 64px 16px 120px 16px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        align-self: stretch;
    }

    .contactedHeader {
        color: var(--Text-Normal, #111);
        text-align: center;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 32px */
    }

    .contactedBody {
        align-self: stretch;
        color: #4B4B4B;
        text-align: center;
        font-family: "Verdana";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
    }

    .contactedHomeButtonContainer {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .contactedHomeButton {
        display: flex;
        height: 44px;
        padding: 15px 37px 15px 37px;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #E4E5DA;
        color: var(--Text-Normal, #111);
        background-color: transparent;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
    }
}


@media screen and (min-width: 848px) and (max-width: 1279px) {

    .entireForm {
        height: 1020px;
    }
    

    .entireContactBody {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98%;
        height: 982px;
    }

    .contactBodyContainer {
        display: flex;
        padding: 64px 120px 120px 120px;
        height: 726px;
        width: 640px;
        flex-direction: column;
        align-items: center;
        gap: 64px;
        align-self: stretch;
        margin-top: 72px;
    }

    .contactHeader {
        align-self: center;
        color: var(--Text-Normal, #111);
        text-align: center;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 32px */
    }

    .contactSubHeaderContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .contactSubHeaderTop {
        color: var(--Text-Normal, #111);
        text-align: center;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 20px */
        align-self: center;
    }

    .contactSubHeaderBottom {
        align-self: center;
        color: #4B4B4B;
        text-align: center;
        font-family: "Verdana";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
    }

    .contactPageAnchor {
        color: #279070;
        font-family: "Verdana";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        text-decoration: none;
    }

    .contactFormContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-self: stretch;
    }

    .contactEntireTopInputsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        align-self: stretch;
        }

        .contactInputContainer {
            display: flex;
            padding-bottom: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
        }

        .contactInputLabel {
            color: var(--Text-Normal, #111);
            font-family: "Verdana";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 25.6px */
        }

        .contactInputField {
            align-self: stretch;
            color: #000000;
            font-family: "Verdana";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 25.6px */
            border: none;
            outline: none;
        }

        .contactInputField::placeholder {
            color: #E4E5DA;
            opacity: 1;
        }
        
        .contactInputSeperator {
            width: 304px;
            height: 1px;
            background: #111;
        } 

        .contactMessageContainer {
            display: flex;
            padding-bottom: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
        }

        /* black text on type, same color for placeholder*/

        .contactMessgageInputField {
            height: 200px;
            align-self: stretch;
            color: #000000;
            font-family: "Verdana";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 25.6px */
            border: none;
            justify-content: baseline;
            align-items: baseline;
            resize: none;
            outline: none;
        }

        .contactMessgageInputField::placeholder {
            color: #E4E5DA;
            opacity: 1;
        }

        .contactMessageSeperator {
            width: 640px;
            height: 1px;
            background: #111;
        }

        .sendButton {
            display: flex;
            height: 44px;
            width: 165px;
            padding: 15px 32px 15px 32px;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            background: var(--Text-Normal, #111);
            color: #FFF;
            font-family: "Verdana";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            border: none;
            margin-top: 40px;
        }

        .entireContactedBody {
            margin-top: 72px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .contactedBodyContainer {
            display: flex;
            padding: 120px 200px 160px 200px;
            flex-direction: column;
            align-items: center;
            gap: 64px;
            align-self: stretch;
            height: 343px;
        }

        .contactedHeader {
            color: var(--Text-Normal, #111);
            text-align: center;
            font-family: 'Montserrat';
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 32px */
        }

        .contactedBody {
            align-self: stretch;
            color: #4B4B4B;
            text-align: center;
            font-family: "Verdana";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 25.6px */
        }

        .contactedHomeButtonContainer {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .contactedHomeButton {
            display: flex;
            height: 44px;
            padding: 15px 44px 15px 37px;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #E4E5DA;
            color: var(--Text-Normal, #111);
            background-color: transparent;
            font-family: "Verdana";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
        }

}


@media screen and (min-width: 1280px) {
    
    .entireContactBody {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98vw;
        height: 982px;
    }

    .contactBodyContainer {
        display: flex;
        padding: 64px 300px 120px 300px;
        height: 726px;
        width: 640px;
        flex-direction: column;
        align-items: center;
        gap: 64px;
        align-self: stretch;
        margin-top: 72px;
    }

    .contactHeader {
        align-self: center;
        color: var(--Text-Normal, #111);
        text-align: center;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 32px */
    }

    .contactSubHeaderContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .contactSubHeaderTop {
        color: var(--Text-Normal, #111);
        text-align: center;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 20px */
        align-self: center;
    }

    .contactSubHeaderBottom {
        align-self: center;
        color: #4B4B4B;
        text-align: center;
        font-family: "Verdana";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
    }

    .contactPageAnchor {
        color: #279070;
        font-family: "Verdana";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        text-decoration: none;
    }

    .contactFormContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        align-self: stretch;
    }

    .contactEntireTopInputsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        align-self: stretch;
        }

        .contactInputContainer {
            display: flex;
            padding-bottom: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
        }

        .contactInputLabel {
            color: var(--Text-Normal, #111);
            font-family: "Verdana";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 25.6px */
        }

        .contactInputField {
            align-self: stretch;
            color: #000000;
            font-family: "Verdana";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 25.6px */
            border: none;
            outline: none;
        }

        .contactInputField::placeholder {
            color: #E4E5DA;
            opacity: 1;
        }

        .contactInputSeperator {
            width: 304px;
            height: 1px;
            background: #111;
        }

        .contactMessageContainer {
            display: flex;
            padding-bottom: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
        }

        /* black text on type, same color for placeholder*/

        .contactMessgageInputField {
            height: 200px;
            align-self: stretch;
            color: #000000;
            font-family: "Verdana";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 25.6px */
            border: none;
            justify-content: baseline;
            align-items: baseline;
            resize: none;
            outline: none;
        }

        .contactMessgageInputField::placeholder {
            color: #E4E5DA;
            opacity: 1;
        }

        .contactMessageSeperator {
            width: 640px;
            height: 1px;
            background: #111;
        }

        .sendButton {
            display: flex;
            height: 44px;
            width: 165px;
            padding: 15px 32px 15px 32px;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            background: var(--Text-Normal, #111);
            color: #FFF;
            font-family: "Verdana";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            border: none;
        }

        .entireContactedBody {
            margin-top: 72px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .contactedBodyContainer {
            display: flex;
            padding: 120px 400px 160px 400px;
            flex-direction: column;
            align-items: center;
            gap: 64px;
            align-self: stretch;
            height: 726px;
        }

        .contactedHeader {
            color: var(--Text-Normal, #111);
            text-align: center;
            font-family: 'Montserrat';
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 32px */
        }

        .contactedBody {
            align-self: stretch;
            color: #4B4B4B;
            text-align: center;
            font-family: "Verdana";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 25.6px */
        }

        .contactedHomeButtonContainer {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .contactedHomeButton {
            display: flex;
            height: 44px;
            padding: 15px 44px 15px 44px;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #E4E5DA;
            color: var(--Text-Normal, #111);
            font-family: "Verdana";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            background-color: transparent;
        }
}