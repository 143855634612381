@media screen and (max-width: 414px) {
    .entireTop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 75px;
    }

    .topContainer {
        display: flex;
        padding: 20px 16px 40px 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
    }

    .topTextContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .topTextTitle {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        color: #111;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 39.2px */
        letter-spacing: 0.84px;
    }

    .topTextSubTitle {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
        margin-top: -16px;
        margin-bottom: -16px;
    }

    .topContactBox1 {
        display: none;
    }

    .topContactBox2 {
        display: none;
    }

    .topContactBox3 {
        display: flex;
        align-items: flex-start;
        gap: 16px;
    }

    .topContactBox3Logo1 {
        width: 24px;
        height: 24px;
    }

    .topContactBox3Logo2Container {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        background: url(../../../../assets/Images/topContactBox3Logo2.png) rgb(255, 255, 255) 70% / cover no-repeat;
        opacity: 80%;
    }

    .topContactBox3Logo2 {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
    }

    .aboutTalkButton {
        display: flex;
        height: 44px;
        padding: 15px 17px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #111;
        color: #FFF;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        border-style: solid;
        border-color: white
    }

    .aboutTalkButton:hover {
        cursor: pointer;
        background: #2e2e2e;
    }
}

@media screen and (min-width: 415px) and (max-width: 847px) {
    .entireTop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 75px;
    }

    .topContainer {
        display: flex;
        padding: 20px 16px 40px 16px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
    }

    .topTextContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .topTextTitle {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        color: #111;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 39.2px */
        letter-spacing: 0.84px;
    }

    .topTextSubTitle {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
        margin-top: -16px;
        margin-bottom: -16px;
    }

    .topContactBox1 {
        display: none;
    }

    .topContactBox2 {
        display: none;
    }

    .topContactBox3 {
        display: flex;
        align-items: flex-start;
        gap: 16px;
    }

    .topContactBox3Logo1 {
        width: 24px;
        height: 24px;
    }

    .topContactBox3Logo2Container {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        background: url(../../../../assets/Images/topContactBox3Logo2.png) rgb(255, 255, 255) 1% / cover no-repeat;
        opacity: 80%;
    }

    .topContactBox3Logo2 {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
    }

    .aboutTalkButton {
        display: flex;
        height: 44px;
        padding: 15px 17px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #111;
        color: #FFF;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        border-style: solid;
        border-color: white
    }

    .aboutTalkButton:hover {
        cursor: pointer;
        background: #2e2e2e;
    }
}

@media screen and (min-width: 848px) and (max-width: 1279px) {

    .anchor {
        color: green;
    }

    .anchor:hover {
        text-decoration: underline;
    }

    .entireTop {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 75px;
    }

    .topContainer{
        display: flex;
        width: 100%;
        max-width: 800px;
        height: auto;
        margin: 64px 64px;
        justify-content: space-evenly;
        align-items: center;
        align-self: stretch;
        gap: 40px;
    }

    .topImage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .topTextContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .topTextTitle {
        color: #111;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 44.8px */
        letter-spacing: 1.6px;
        gap: 24px;
    }

    .topTextSubTitle {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.8px;
        padding-bottom: 8px;
    }

    .separator {
        width: 340px;
        height: 1px;
        background: #E4E5DA;
    }

    .topContactBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        padding-top: 8px;
    }

    .topContactBox1 {
        display: flex;
        color: #111;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.8px;
    }

    .topContactBox2 {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.8px;
    }

    .topContactBox3 {
        display: flex;
        align-items: flex-start;
        gap: 16px;
    }

    .topContactBox3Logo1 {
        width: 24px;
        height: 24px;
    }

    .topContactBox3Logo2Container {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        background: url(../../../../assets/Images/topContactBox3Logo2.png) rgb(255, 255, 255) 1% / cover no-repeat;
        opacity: 80%;
    }

    .topContactBox3Logo2 {
        width: 22px;
    height: 22px;
    flex-shrink: 0;
    }

    .aboutTalkButton {
        display: none;
    }
}

@media screen and (min-width: 1280px) {

    .anchor {
        color: green;
    }

    .anchor:hover {
        text-decoration: underline;
    }

    .entireTop {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 75px;
    }

    .topContainer{
        display: flex;
        width: 100%;
        max-width: 880px;
        height: auto;
        margin: 76px 80px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        gap: 64px;
    
    }

    .topImage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .topTextContainer {
        display: flex;
        width: 408px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .topTextTitle {
        color: #111;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 44.8px */
        letter-spacing: 1.6px;
        gap: 24px;
    }

    .topTextSubTitle {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0px;
        padding-bottom: 8px;
    }

    .separator {
        width: 100%;
        max-width: 408px;
        height: 1px;
        background: #E4E5DA;
    }

    .topContactBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        padding-top: 8px;
    }

    .topContactBox1 {
        display: flex;
        color: #111;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.8px;
    }

    .topContactBox2 {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0px;
    }

    .topContactBox3 {
        display: flex;
        align-items: flex-start;
        gap: 16px;
    }

    .topContactBox3Logo1 {
        width: 24px;
        height: 24px;
    }

    .topContactBox3Logo2Container {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        background: url(../../../../assets/Images/topContactBox3Logo2.png) rgb(255, 255, 255) 1% / cover no-repeat;
        opacity: 80%;
    }

    .topContactBox3Logo2 {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
    }

    .aboutTalkButton {
        display: none;
    }

}