

@media screen and (max-width: 414px) {

    .entireWorksBody {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .worksBodyContainer {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        padding: 24px 16px 40px 16px;
        width: 100%;
        max-width: 383px;
        height: auto;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        gap: 40px;
    }

    .worksBodyKeyPointsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .worksBodyKeyPointsHeader {
        flex: 1 0 0;
        color: #111;
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 28.8px */
    }

    .worksBodyKeyPointsDescription {
        align-self: stretch;
        color: #111;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }

    .worksBodyKeyPointsDetailContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBulletContainer {
        display: flex;
        padding-top: 12px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBullet {
        width: 8px;
        height: 1px;
        background: #8D8D8D;
    }

    .worksBodyKeyPointsDetail {
        flex: 1 0 0;
        color: var(--Text-Normal, #111);
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }

    .worksBodyAnchor {
        color: #279070;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        text-decoration: none;
        margin-top: 4px;
    }

    .worksBodyImage {
      display: flex;
      width: 100%;
      border-radius: 4px;
    }

    .worksBodyImageSubtext {
        display: flex;
        justify-content: center;
        color: #4B4B4B;
        text-align: center;
        justify-self: center;
        font-family: "Verdana";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        margin-top: 8px;
    }

    .outroContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: center;
      color: #111;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 28.8px */
    }

}

@media screen and (min-width: 415px) and (max-width: 847px) {

    .entireWorksBody {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .worksBodyContainer {
        display: flex;
        flex-direction: column;
        padding: 24px 16px 40px 16px;
        width: 100%;
        max-width: 781px;
        align-items: center;
        gap: 40px;
        align-self: stretch;
    }

    .worksBodyKeyPointsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .worksBodyKeyPointsHeader {
        flex: 1 0 0;
        color: #111;
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 28.8px */
    }

    .worksBodyKeyPointsDescription {
        align-self: stretch;
        color: #111;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }

    .worksBodyKeyPointsDetailContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBulletContainer {
        display: flex;
        padding-top: 12px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBullet {
        width: 8px;
        height: 1px;
        background: #8D8D8D;
    }

    .worksBodyKeyPointsDetail {
        flex: 1 0 0;
        color: var(--Text-Normal, #111);
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }

    .worksBodyAnchor {
        color: #279070;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        text-decoration: none;
        margin-top: 4px;
    }

    .worksBodyImage {
      display: flex;
      width: 100%;
      border-radius: 4px;
    }

    .worksBodyImageSubtext {
        display: flex;
        justify-content: center;
        color: #4B4B4B;
        text-align: center;
        justify-self: center;
        font-family: "Verdana";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        margin-top: 8px;
    }

    .outroContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: center;
      color: #111;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 28.8px */
    }

}

@media screen and (min-width: 848px) and (max-width: 1279px) {

    .entireWorksBody {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: verdana;
        padding: 64px 90px;
    }

    .worksBodyContainer {
        display: flex;
        width: 100%;
        max-width: 800px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 64px;
    }

    .worksBodyKeyPointsContainer {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }

    .worksBodyKeyPointsHeader {
        color: #111;
        font-family: 'Montserrat';
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 41.6px */
        align-self: stretch;
    }

    .worksBodyKeyPointsDescription {
        color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBulletContainer {
        display: flex;
        padding-top: 16px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBullet {
        width: 8px;
        height: 1px;
        background: #8D8D8D;
    }

    .worksBodyKeyPointsDetailAndAnchorContainer {
        gap: 4px;
    }

    .worksBodyKeyPointsDetail {
        flex: 1 0 0;
        color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
    }

    .worksBodyAnchor {
        color: #279070;
        font-family: Verdana;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        text-decoration: none;
        align-self: stretch;
    }

    .worksBodyImage {
      display: flex;
      width: 100%;
      border-radius: 4px;
    }
 
    .worksBodyImageSubtext {
        display: flex;
        justify-content: center;
        color: #4B4B4B;
        text-align: center;
        justify-self: center;
        font-family: "Verdana";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        margin-top: 16px;
    }

    .outroContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: center;
      color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
    }

}

@media screen and (min-width: 1280px) {

    .entireWorksBody {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: verdana;
        padding: 64px 240px;
    }

    .worksBodyContainer {
        display: flex;
        width: 100%;
        max-width: 800px;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 64px;
    }

    .worksBodyKeyPointsContainer {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }

    .worksBodyKeyPointsHeader {
        color: #111;
        font-family: 'Montserrat';
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 41.6px */
        align-self: stretch;
    }

    .worksBodyKeyPointsDescription {
        color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBulletContainer {
        display: flex;
        padding-top: 16px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBullet {
        width: 8px;
        height: 1px;
        background: #8D8D8D;
    }

    .worksBodyKeyPointsDetailAndAnchorContainer {
        gap: 4px;
    }

    .worksBodyKeyPointsDetail {
        flex: 1 0 0;
        color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
    }

    .worksBodyAnchor {
        color: #279070;
        font-family: Verdana;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        text-decoration: none;
        align-self: stretch;
    }

    .worksBodyImage {
      display: flex;
      width: 100%;
      border-radius: 4px;
    }
 
    .worksBodyImageSubtext {
        display: flex;
        justify-content: center;
        color: #4B4B4B;
        text-align: center;
        justify-self: center;
        font-family: "Verdana";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        margin-top: 16px;
    }

    .outroContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: center;
      color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 28.8px */
    }
}