@media screen and (max-width: 414px) {
    .entireAbout {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .aboutContainer {
        display: flex;
        padding: 64px 16px;
        width: 100%;
        max-width: 382px;
        height: auto;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .aboutTitleText {
        color: #111;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24px */
    }

    .aboutImgContainer {
        display: flex;
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;

    }

    .aboutImg {
        background: url(../../../../assets/Images/topImage.png) rgb(255, 255, 255) 50% / cover no-repeat;
        width: 100%;
        max-width: 173px;
        height: auto;
    }

    .aboutHeaderText {
        align-self: stretch;
        color: #111;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 35.2px */
        margin-top: -32px;
        margin-bottom: -32px;
    }

    .aboutBodyText {
        color: #111;
        text-align: center;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        letter-spacing: 0.7px;
    }

    .aboutQualifications {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        border-top: 1px solid #E4E5DA;
        border-bottom: 1px solid #E4E5DA;
    }

    .aboutQualificationsEducation {
        display: flex;
        padding: 24px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .aboutQualificationsTitle {
        color: #111;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 20px */
    }

    .aboutQualificationsEducationItemSubTitle {
        color: #000;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .aboutQualificationsEducationItems {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .aboutQualificationsEducationItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1 0 0;
        align-self: stretch;
        color: #111;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding-bottom: 16px;
    }

    .aboutQualificationsEducationItemTitle {
        color: #111;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .aboutQualificationsEducationItemDate {
        align-self: stretch;
        color: #707070;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
        margin-top: 16px;
    }

    .aboutQualificationsHobbies {
        border-top: 1px solid #E4E5DA;
        padding-top: 24px;
    }

    .aboutQualificationsHobbiesDescription {
        align-self: stretch;
        color: #111;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        padding-top: 16px;
    }

    .aboutLinks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;
    }

    .aboutAnchor {
        color: #111;
        text-decoration: none;
        text-align: center;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
    }

    .aboutAnchor:hover {
        color: green;
        transition: .5s;
    }

    .aboutTalkButton {
        display: flex;
        height: 44px;
        padding: 15px 17px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #111;
        color: #FFF;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        background: #111;
        color: white;
        border: none;
    }

    .aboutTalkButton:hover {
        cursor: pointer;
        background: #2e2e2e;
    }
}

@media screen and (min-width: 415px) and (max-width: 847px) {
    .entireAbout {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .aboutContainer {
        display: flex;
        padding: 64px 16px;
        max-width: 781px;
        height: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }

    .aboutTitleText {
        color: #111;
        text-align: center;
        align-self: center;
        font-family: 'Montserrat';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24px */
    }

    .aboutImgContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        width: 100%;
        height: auto;
    }

    .aboutImg {
        width: 100%;
        max-width: 248px;
        height: auto;
    }

    .aboutHeaderText {
        align-self: stretch;
        color: #111;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 35.2px */
        margin-top: -32px;
        margin-bottom: -32px;
    }

    .aboutBodyText {
        color: #111;
        text-align: center;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        letter-spacing: 0.7px;
    }

    .aboutQualifications {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        border-top: 1px solid #E4E5DA;
        border-bottom: 1px solid #E4E5DA;
    }

    .aboutQualificationsEducation {
        display: flex;
        padding: 24px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .aboutQualificationsTitle {
        color: #111;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 20px */
    }

    .aboutQualificationsEducationItemSubTitle {
        color: #000;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .aboutQualificationsEducationItems {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .aboutQualificationsEducationItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1 0 0;
        align-self: stretch;
        color: #111;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding-bottom: 16px;
    }

    .aboutQualificationsEducationItemTitle {
        color: #111;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .aboutQualificationsEducationItemDate {
        align-self: stretch;
        color: #707070;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
        margin-top: 16px;
    }

    .aboutQualificationsHobbies {
        border-top: 1px solid #E4E5DA;
        padding-top: 24px;
    }

    .aboutQualificationsHobbiesDescription {
        align-self: stretch;
        color: #111;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        padding-top: 16px;
    }

    .aboutLinks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;
    }

    .aboutAnchor {
        color: #111;
        text-decoration: none;
        text-align: center;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
    }

    .aboutAnchor:hover {
        color: green;
        transition: .5s;
    }

    .aboutTalkButton {
        display: flex;
        height: 44px;
        padding: 15px 17px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #111;
        color: #FFF;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        background: #111;
        color: white;
        border: none;
    }

    .aboutTalkButton:hover {
        cursor: pointer;
        background: #2e2e2e;
    }
}

@media screen and (min-width: 848px) and (max-width: 1279px) {
    .entireAbout {
        display: flex;
        padding: 64px 80px 120px 80px;
        align-items: center;
        justify-content: center;
    }

    .aboutContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        align-self: stretch;
        max-width: 800px;
        width: 100%;
    }

    .aboutTitleText {
        color: #000;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 32px */
    }

    .aboutImgContainer {
        display: flex;
        width: 100%;
        max-width: 324px;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .aboutImg {
        width: 100%;
        height: auto;
    }

    .aboutHeaderText {
        color: #111;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 44.8px */
        margin-top: -24px;
        margin-bottom: -24px;
    }
    
    .aboutBodyText {
        color: #111;
        text-align: center;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%; /* 28.8px */
    }
    
    .aboutQualifications {
        display: flex;
        padding: 24px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }
    
    .aboutQualificationsEducation {
        display: flex;
        padding: 24px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-top: 1px solid #E4E5DA;
    }
    
    .aboutQualificationsTitle {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        color: #000;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 20px */
    }

    .aboutQualificationsEducationItemSubTitle {
        color: #000;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    
    .aboutQualificationsEducationItems {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 40px;
        align-self: stretch;
        
      
    }
    
    .aboutQualificationsEducationItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
    }
    
    .aboutQualificationsEducationItemTitle {
        display: flex;
        flex-direction: column;
        color: #000;
        font-family: Verdana;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    
    .aboutQualificationsEducationItemDate {
        color: #707070;
        font-family: Verdana;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 13px */
        margin-top: 16px;
    }
    
    .aboutQualificationsHobbies {
        display: flex;
        padding: 24px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-top: 1px solid #E4E5DA;
        border-bottom: 1px solid #E4E5DA;
        color: #000;
        font-family: Verdana;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }
    
    .aboutLinks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;
        text-decoration: none;
        color: black;
        text-align: center;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }

    .aboutAnchor {
        color: black;
        text-decoration: none;
    }

    .aboutAnchor:hover {
        color: green;
        transition: 0.5s;
    }

    .aboutTalkButton {
        display: flex;
        height: 44px;
        padding: 15px 17px;
        justify-content: center;
        color: #FFF;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        align-items: center;
        border-radius: 10px;
        background: #111;
        color: white;
        border: none;
    }

    .aboutTalkButton:hover {
        cursor: pointer;
        background: #2e2e2e;
    }
}

@media screen and (min-width: 1280px) {
    .entireAbout {
        display: flex;
        margin: 64px 200px 120px 200px;
        align-items: center;
        justify-content: center;
    }

    .aboutContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        align-self: stretch;
        max-width: 880px;
        width: 100%;
    }

    .aboutTitleText {
        text-align: center;
        color: #000;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 32px */
    }

    .aboutImgContainer {
        width: 100%;
        max-width: 324px;
        height: auto;
    }

    .aboutImg {
        background: url(../../../../assets/Images/topImage.png) rgb(255, 255, 255) 50% / cover no-repeat;
        width: 100%;
        height: auto;
    }

    .aboutHeaderText {
        color: #111;
        text-align: center;
        font-family: 'Montserrat';
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 44.8px */
        margin-top: -24px;
        margin-bottom: -24px;
    }

    .aboutBodyText {
        color: #111;
        text-align: center;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%; /* 28.8px */
    }

    .aboutQualifications {
        display: flex;
        padding: 24px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .aboutQualificationsEducation {
        display: flex;
        padding: 24px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        border-top: 1px solid #E4E5DA;
    }

    .aboutQualificationsTitle {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        color: #000;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 20px */
    }

    .aboutQualificationsEducationItemSubTitle {
        color: #000;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .aboutQualificationsEducationItems {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 40px;
        align-self: stretch;
        margin-top: -8px;
    }

    .aboutQualificationsEducationItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
    }

    .aboutQualificationsEducationItemTitle {
        display: flex;
        flex-direction: column;
        color: #000;
        font-family: Verdana;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .aboutQualificationsEducationItemDate {
        color: #707070;
        font-family: Verdana;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 13px */
        margin-top: 16px;
    }

    .aboutQualificationsHobbies {
        display: flex;
        padding: 24px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-top: 1px solid #E4E5DA;
        border-bottom: 1px solid #E4E5DA;
        color: #000;
        font-family: Verdana;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }

    .aboutLinks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;
        text-decoration: none;
        color: black;
        text-align: center;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }

    .aboutAnchor {
        color: black;
        text-decoration: none;
    }

    .aboutAnchor:hover {
        color: green;
        transition: 0.5s;
    }

    .aboutTalkButton {
        display: flex;
        height: 44px;
        padding: 15px 17px;
        justify-content: center;
        color: #FFF;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        align-items: center;
        border-radius: 10px;
        background: #111;
        color: white;
        border: none;
    }

    .aboutTalkButton:hover {
        cursor: pointer;
        background: #2e2e2e;
    }

}