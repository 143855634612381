@media screen and (max-width: 414px) {

    .entireWorksAbout {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #E4E5DA;
    }

    .worksHeaderContainer {
        display: flex;
        margin: 24px 16px 40px 16px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }

    .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 10px;
        height: 22px;
        width: 46px;
        gap: 10px;
        color: #777;
        font-family: "Verdana";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
        display: flex;
        padding: 6px 10px;
        gap: 10px;
        border-radius: 10px;
        background: #F5F5F0;
        margin-bottom: 8px;
    }

    .projectTitle {
        flex: 1 0 0;
        color: #111;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 32px */
        margin-bottom: 8px;
    }

    .projectDescription {
        align-self: stretch;
        color: #4B4B4B;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        margin-bottom: 16px;
    }

    .projectVisitButton {
        display: flex;
        padding: 12px 17px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #111;
        background-color: white;
        color: #111;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }

    .worksHeaderBottomContainer {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        background: #F5F5F0;
    }

    .workHeaderDetailsContainer {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    .workDetailsImages {
        width: 18px;
        height: 18px;
    }

    .workDetailsType {
        color: #8D8D8D;
        font-family: "Verdana";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 20.8px */
    }

    .workDetailsDetails {
        flex: 1 0 0;
        color: #111;
        font-family: "Verdana";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 20.8px */
    }
}

@media screen and (min-width: 415px) and (max-width: 847px) {

    .entireWorksAbout {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #E4E5DA;
    }

    .worksHeaderContainer {
        display: flex;
        margin: 24px 16px 40px 16px;
        max-width: 781px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }

    .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 10px;
        height: 22px;
        width: 46px;
        gap: 10px;
        color: #777;
        font-family: "Verdana";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
        display: flex;
        padding: 6px 10px;
        gap: 10px;
        border-radius: 10px;
        background: #F5F5F0;
        margin-bottom: 8px;
    }

    .projectTitle {
        flex: 1 0 0;
        color: #111;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 32px */
        margin-bottom: 8px;
    }

    .projectDescription {
        align-self: stretch;
        color: #4B4B4B;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        margin-bottom: 16px;
    }

    .projectVisitButtonContainer {
    }

    .projectVisitButton {
        display: flex;
        padding: 12px 17px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #111;
        background-color: white;
        color: #111;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }

    .worksHeaderBottomContainer {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        background: #F5F5F0;
    }

    .workHeaderDetailsContainer {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    .workDetailsImages {
        width: 18px;
        height: 18px;
    }

    .workDetailsType {
        color: #8D8D8D;
        font-family: "Verdana";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 20.8px */
    }

    .workDetailsDetails {
        flex: 1 0 0;
        color: #111;
        font-family: "Verdana";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 20.8px */
    }
}

@media screen and (min-width: 848px) and (max-width: 1279px) {
    .entireWorksAbout {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #E4E5DA;
    }

    .worksHeaderContainer {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 100%;
        max-width: 800px;
        margin: 40px 64px 64px 64px;
    }

    .worksHeaderTopContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .tag {
        display: flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #F5F5F0;
        color: #777;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }

    .projectTitle {
        color: #111;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 32px */
    }

    .projectDescription {
        color: #4B4B4B;
        font-family: Verdana;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        align-self: stretch;
    }

    .projectVisitButton {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #E4E5DA;
        color: #111;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        background-color: rgb(255, 255, 255);
    }

    .projectVisitButton:hover {
        background-color: rgb(230, 229, 229);
        transition: 0.5s;
        cursor: pointer;
    }

    .worksHeaderBottomContainer {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        background: #F5F5F0;
    }

    .workHeaderDetailsContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    .workDetailsImagesContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
    }

    .workDetailsImages {
        width: 18px;
        height: 18px;
    }

    .workDetailsType {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
    }

    .workDetailsDetails {
        flex: 1 0 0;
        color: #111;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
    }
}

@media screen and (min-width: 1280px) {

    .entireWorksAbout {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #E4E5DA;
    }

    .worksHeaderContainer {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 100%;
        max-width: 800px;
        padding: 40px 240px 64px 240px;
    }

    .worksHeaderTopContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .tag {
        display: flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #F5F5F0;
        color: #777;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }

    .projectTitle {
        color: #111;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 32px */
    }

    .projectDescription {
        color: #4B4B4B;
        font-family: Verdana;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        align-self: stretch;
    }

    .projectVisitButton {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #E4E5DA;
        color: #111;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        background-color: rgb(255, 255, 255);
    }

    .projectVisitButton:hover {
        background-color: rgb(230, 229, 229);
        transition: 0.5s;
        cursor: pointer;
    }

    .worksHeaderBottomContainer {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        background: #F5F5F0;
    }

    .workHeaderDetailsContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }

    .workDetailsImagesContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
    }

    .workDetailsImages {
        width: 18px;
        height: 18px;
    }

    .workDetailsType {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
    }

    .workDetailsDetails {
        flex: 1 0 0;
        color: #111;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
    }
}