

@media screen and (max-width: 414px) {
    .navUL {
        display: none;
    }

    .navAndMenu {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .entireNavbar {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 72px;
        background-color: white;
        z-index: 100;
        top: 0;
    }

    .navContainer {
        display: flex;
        width: 100%;
        max-width: 846px;
        height: 72px;
        padding: 0px 16px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

   .navSignature {
        color: #111;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 16.8px */
        letter-spacing: 0.42px;
   }

   .navMenuContainer {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 4px 3px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
   }

   .slideMenuActive {
        background-color: white;
        height: 105vh;
        position: fixed;
        width: 100vw;
        z-index: 10;
        transition: 0.25s;
        left: 0%;
        margin-top: -3px;
   }

   .slideMenuInactive {
        background-color: white;
        height: 105vh;
        position: fixed;
        width: 100vw;
        z-index: 10;
        transition: 0.25s;
        left: 100%;
        margin-top: -3px;
   }

   .slideMenuContainer {
        display: flex;
        height: 528px;
        padding: 120px 40px 40px 40px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;
   }

   .slideMenuTopBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 48px;
        width: 160px;
        height: 236px;
   }

    .navULliItemTalkBox {
        align-self: stretch;
        color: #FFF;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
        display: flex;
        padding: 14px 28.5px 14px 27.5px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #111;
        border: none;
        transition: none;
    }

    .navULliItemTalkBox:hover {
        cursor: pointer;
    }

    .navULliItem {
        color: #111;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
        list-style: none;
    }

    .navULliItem:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .slideMenuBottomBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }

    .slideMenuExternalLinks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 32px;
        text-decoration: none;
    }

    .slideMenuAnchor {
        color: #111;
        text-align: center;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        text-decoration: none;
    }

    .footerFooter {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 16.8px */
    }

}

@media screen and (min-width: 415px) and (max-width: 847px) {
    .navUL {
        display: none;
    }

    .navAndMenu {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .entireNavbar {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 72px;
        background-color: white;
        z-index: 100;
        top: 0;
    }


    .navContainer {
        display: flex;
        width: 100%;
        max-width: 846px;
        height: 72px;
        padding: 0px 32px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .navSignature {
        color: #111;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 16.8px */
        letter-spacing: 0.42px;
    }

   .slideMenuActive {
        background-color: white;
        height: 105vh;
        position: fixed;
        width: 100vw;
        z-index: 10;
        transition: 0.25s;
        left: 0%;
        margin-top: -3px;
   }

   .slideMenuInactive {
        background-color: white;
        height: 105vh;
        position: fixed;
        width: 100vw;
        z-index: 10;
        transition: 0.25s;
        left: 100%;
        margin-top: -3px;
   }

   .slideMenuContainer {
        display: flex;
        height: 528px;
        padding: 120px 40px 40px 40px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;
   }

   .slideMenuTopBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 48px;
        width: 160px;
        height: 236px;
   }

    .navULliItemTalkBox {
        align-self: stretch;
        color: #FFF;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
        display: flex;
        padding: 14px 28.5px 14px 27.5px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #111;
        border: none;
        transition: none;
    }

    .navULliItemTalkBox:hover {
        cursor: pointer;
    }

    .navULliItem {
        color: #111;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
        list-style: none;
    }

    .navULliItem:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .slideMenuBottomBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }

    .slideMenuExternalLinks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 32px;
        text-decoration: none;
    }

    .slideMenuAnchor {
        color: #111;
        text-align: center;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        text-decoration: none;
    }

    .footerFooter {
        color: #8D8D8D;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 16.8px */
    }

}

@media screen and (min-width: 848px) and (max-width: 1279px) {

    .navMenuContainer {
        display: none;
    }

    .slideMenuActive {
        display: none;
    }

    .slideMenuInactive {
        display: none;
    }

    .navMenuContainer {
        display: none;
    }

    .entireNavbar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 1280px;
        background-color: white;
        z-index: 100;
        top: 0;
        position: fixed;
    }

    .navContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        width: 100%;
        max-width: 1440px;
        padding: 16px 40px;
        margin-left: 0px;
    }

    .navSignature {
        color: #111;
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 18px */
        letter-spacing: 0.54px;
    }

    .navUL {
        display: flex;
        align-items: center;
        gap: 32px;
    }

    .navULContainer {
        display: flex;
        align-items: center;
        gap: 32px;
        text-decoration: none;
    }

    .navULliItem {
        color: #111;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        list-style: none;
    }

    .navULliItem:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .navULliItemTalkBox {
        display: flex;
        height: 44px;
        padding: 15px 17px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #111;
        color: white;
        border: none;
    }

    .navULliItemTalkBox:hover {
        cursor: pointer;
        background: #2e2e2e;
    }
}

@media screen and (min-width: 1280px) {

    .slideMenuActive {
        display: none;
    }

    .slideMenuInactive {
        display: none;
    }
    .navMenuContainer {
        display: none;
    }

    .entireNavbar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: white;
        z-index: 100;
        top: 0;
        position: fixed;
    }

    .navContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        width: 100%;
        max-width: 1440px;
        padding: 16px 40px;
    }

    .navSignature {
        color: #111;
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 18px */
        letter-spacing: 0.54px;
    }

    .navUL {
        display: flex;
        align-items: center;
        gap: 32px;
    }

    .navULContainer {
        display: flex;
        align-items: center;
        gap: 32px;
        text-decoration: none;
    }

    .navULliItem {
        color: #111;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        list-style: none;
    }

    .navULliItem:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .navULliItemTalkBox {
        display: flex;
        height: 44px;
        
        padding: 15px 17px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #111;
        color: white;
        border: none;
    }

    .navULliItemTalkBox:hover {
        cursor: pointer;
        background: #2e2e2e;
    }

}
