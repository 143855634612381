@media screen and (max-width: 414px) {

    .entireWorksBody {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .worksBodyContainer {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        padding: 24px 16px 40px 16px;
        max-width: 382px;
        align-items: center;
        gap: 40px;
        align-self: stretch;
    }

    .worksBodyKeyPointsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .worksBodyKeyPointsHeader {
        flex: 1 0 0;
        color: #111;
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 28.8px */
    }

    .worksBodyKeyPointsDescription {
        align-self: stretch;
        color: #111;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }

    .worksBodyKeyPointsDetailContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBulletContainer {
        display: flex;
        padding-top: 12px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBullet {
        width: 8px;
        height: 1px;
        background: #8D8D8D;
    }

    .worksBodyKeyPointsDetail {
        flex: 1 0 0;
        color: var(--Text-Normal, #111);
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }

    .worksBodyAnchor {
        color: #279070;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        text-decoration: none;
        margin-top: 4px;
    }

    .worksBodyCodeWindow1 {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    .worksBodyCodeWindow2 {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    .worksBodyCodeWindow1Image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .worksBodyCodeWindow2Image {
 
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .worksBodyDesignImageBox1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .worksBodyDesignImageBox2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .worksBodyDesignImageContainer1 {
        width: 100%;
        height: auto;
        background-size: contain;
        background-position: center ;
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    .worksBodyDesignImageBoxSubtext {
        align-self: center;
        color: #4B4B4B;
        text-align: center;
        font-family: "Verdana";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
        margin-top: 8px;
    }

    .worksBodyDesignImageContainer2 {
        width: 100%;
        height: auto;
        background-size: contain;
        background-position: center ;
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    .worksBodyDesignImage1 {
        width: 100%;
        height: auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    .worksBodyDesignImage2 {
        width: 100%;
        height: auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
    }
}

@media screen and (min-width: 415px) and (max-width: 847px) {

    .entireWorksBody {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .worksBodyContainer {
        display: flex;
        flex-direction: column;
        margin: 24px 16px 40px 16px;
        width: 100%;
        max-width: 781px;
        align-items: center;
        gap: 40px;
        align-self: stretch;
    }

    .worksBodyKeyPointsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .worksBodyKeyPointsHeader {
        flex: 1 0 0;
        color: #111;
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 28.8px */
    }

    .worksBodyKeyPointsDescription {
        align-self: stretch;
        color: #111;
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }

    .worksBodyKeyPointsDetailContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBulletContainer {
        display: flex;
        padding-top: 12px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBullet {
        width: 8px;
        height: 1px;
        background: #8D8D8D;
    }

    .worksBodyKeyPointsDetail {
        flex: 1 0 0;
        color: var(--Text-Normal, #111);
        font-family: "Verdana";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
    }

    .worksBodyAnchor {
        color: #279070;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        text-decoration: none;
        margin-top: 4px;
    }

    .worksBodyCodeWindow1 {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #1b1b1b;
    }

    .worksBodyCodeWindow2 {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #1b1b1b;
    }

    .worksBodyCodeWindow1Image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .worksBodyCodeWindow2Image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .worksBodyDesignImageBox1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .worksBodyDesignImageBox2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .worksBodyDesignImageContainer1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .worksBodyDesignImage1 {
        width: 100%;
        height: auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    .worksBodyDesignImageBoxSubtext {
        align-self: center;
        color: #4B4B4B;
        text-align: center;
        font-family: "Verdana";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
        margin-top: 8px;
    }

    .worksBodyDesignImageContainer2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
      }

    .worksBodyDesignImage2 {
        width: 100%;
        height: auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 4px;
        background-color: #fcfcfc;
    }
}

@media screen and (min-width: 848px) and (max-width: 1279px) {

    .entireWorksBody {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: verdana;
        padding: 64px 90px;
    }

    .worksBodyContainer {
        display: flex;
        width: 100%;
        max-width: 800px;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 64px;
    }

    .worksBodyKeyPointsContainer {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }

    .worksBodyKeyPointsHeader {
        color: #111;
        font-family: 'Montserrat';
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 41.6px */
        align-self: stretch;
    }

    .worksBodyKeyPointsDescription {
        color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBulletContainer {
        display: flex;
        padding-top: 16px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBullet {
        width: 8px;
        height: 1px;
        background: #8D8D8D;
    }

    .worksBodyKeyPointsDetailAndAnchorContainer {
        gap: 4px;
    }

    .worksBodyKeyPointsDetail {
        flex: 1 0 0;
        color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
    }

    .worksBodyAnchor {
        color: #279070;
        font-family: Verdana;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        text-decoration: none;
        align-self: stretch;
    }

    .worksBodyCodeWindow1 {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        border: solid 1px #E4E5DA;;
        border-radius: 4px;
        font-size: 18px;
    }

    .worksBodyCodeWindow2 {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        border: solid 1px #E4E5DA;;
        border-radius: 4px;
        font-size: 18px;
    }

   .worksBodyCodeWindow1Image {
        height: auto;
        width: 100%;
        border-radius: 4px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

   }

   .worksBodyCodeWindow2Image {
        height: auto;
        width: 100%;
        border-radius: 4px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .worksBodyDesignImageBox1 {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       width: 100%;
       height: auto;
       gap: 16px;
    }

    .worksBodyDesignImageBox2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        gap: 16px;
        margin-top: -12px;
     }


    .worksBodyDesignImageContainer1 {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        border: solid 1px #E4E5DA;;
        border-radius: 4px;

    }

    .worksBodyDesignImage1 {
        width: 100%;
        height: auto;
        border-radius: 4px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .worksBodyDesignImageBoxSubtext {
        color: #4B4B4B;
        text-align: center;
        font-family: "Verdana";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */

    }

    .worksBodyDesignImageContainer2 {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

       .worksBodyDesignImage2 {
        width: 100%;
        height: auto;
        border-radius: 4px;
        background-size: cover;
        background-position: center;
    }


}

@media screen and (min-width: 1280px) {

    .entireWorksBody {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: verdana;
        padding: 64px 240px;
    }

    .worksBodyContainer {
        display: flex;
        width: 100%;
        max-width: 800px;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 64px;
    }

    .worksBodyKeyPointsContainer {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }

    .worksBodyKeyPointsHeader {
        color: #111;
        font-family: 'Montserrat';
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 41.6px */
        align-self: stretch;
    }

    .worksBodyKeyPointsDescription {
        color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBulletContainer {
        display: flex;
        padding-top: 16px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .worksBodyKeyPointsDetailBullet {
        width: 8px;
        height: 1px;
        background: #8D8D8D;
    }

    .worksBodyKeyPointsDetailAndAnchorContainer {
        gap: 4px;
    }

    .worksBodyKeyPointsDetail {
        flex: 1 0 0;
        color: #111;
        font-family: "Verdana";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
    }

    .worksBodyAnchor {
        color: #279070;
        font-family: Verdana;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        text-decoration: none;
        align-self: stretch;
    }

    .worksBodyCodeWindow1 {
        display: flex;
        flex-direction: row;
        width: 801px;
        height: 442px;
        border: solid 1px #E4E5DA;;
        border-radius: 4px;
        font-size: 18px;
    }

    .worksBodyCodeWindow2 {
        display: flex;
        flex-direction: row;
        width: 801px;
        height: 536px;
        border: solid 1px #E4E5DA;;
        border-radius: 4px;
        font-size: 18px;
    }

   .worksBodyCodeWindow1Image {
        background-image: url(../../../../assets/Images/Portfolio/WorksBodyCodeWindow1Image.png);
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background-size: cover;
   }

   .worksBodyCodeWindow2Image {
        background-image: url(../../../../assets/Images/Portfolio/WorksBodyCodeWindow2Image.png);
        height: 100%;
        width: 100%;
        border-radius: 4px;
        object-fit: cetn ;
        align-self: stretch;
        background-repeat: no-repeat;
    }

    .worksBodyDesignImageBox1 {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       width: 100%;
       height: 100%;
       gap: 16px;
    }

    .worksBodyDesignImageBox2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 16px;
     }


    .worksBodyDesignImageContainer1 {
        display: flex;
        flex-direction: row;
        width: 801px;
        height: 638px;
        border: solid 1px #E4E5DA;;
        border-radius: 4px;

    }

    .worksBodyDesignImage1 {
        background-image: url(../../../../assets/Images/Portfolio/worksBodyDesignImage1.png);
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .worksBodyDesignImageBoxSubtext {
        color: #4B4B4B;
        text-align: center;
        font-family: "Verdana";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */

    }

    .worksBodyDesignImageContainer2 {
        display: flex;
        flex-direction: row;
        width: 801px;
        height: 535px;
        border-radius: 4px;
    }

       .worksBodyDesignImage2 {
        background-image: url(../../../../assets/Images/Portfolio/worksBodyDesignImage2.png);
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background-size: cover;
        background-position: center;
    }


}