@media screen and (max-width: 414px) {
    .entireHero {
        margin-top: 72px;
        display: flex;
        justify-content: center;
    }

    .heroContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px 16px;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .heroImageContainer {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .heroImage {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
    }

}

@media screen and (min-width: 415px) and (max-width: 847px) {
    .entireHero {
        margin-top: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .heroContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px 16px;
        width: 100%;
        max-width: 781px;
        height: auto;
        border-radius: 4px;
    }

    .heroImageContainer {
        width: 100%;
        height: 100%;
    }

    .heroImage {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
    }
}

@media screen and (min-width: 848px) and (max-width: 1279px) {
    .entireHero {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .heroContainer {
        display: flex;
        width: 100%;
        max-width: 960px;
        height: auto;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        margin: 84px 40px 0px 40px;
        border-radius: 4px;
    }

    .heroImageContainer {
        width: 100%;
        height: 100%;
        box-shadow: none;
    }

    .heroImage {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        box-shadow: none;
    }
}

@media screen and (min-width: 1280px) {
    .entireHero {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .heroContainer {
        display: flex;
        width: 100%;
        max-width: 960px;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 84px 200px 0px 200px;
        border-radius: 4px;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .heroImageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        box-shadow: none;
    }

    .heroImage {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 4px;
        width: 100%;
        height: auto;
    }
}