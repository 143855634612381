body {
    margin: 0px;
    padding: 0px;
}

h1 , h2, h3, h4, h5, h6, b {
    padding: 0px;
    margin: 0px;
    font-weight: 600;
}

a {
  text-decoration: none;
  color: #279070;
}

a:hover {
  cursor: pointer;
  opacity: .775;
}

a:visited {
  color: #279070
}

p {
  padding: 0px;
  margin: 0px;
}

.worksCarouselButtons:active {
    background-color: transparent;
}

.worksCarouselSlider:active {
    background-color: transparent;
}