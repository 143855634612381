
@media screen and (max-width: 414px) {
    .entireExp {
        display: flex;
        background: #F5F5F0;
    }

    .expContainer {
        display: flex;
        padding: 64px 16px;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
        gap: 40px;
    }

    .profExp {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .profExpHeader {
        padding-bottom: 14px;
        color: #111;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 20px */
    }

    .profExpItems {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .profExpItem {
        display: flex;
        padding: 24px 0px;
        flex-direction: row;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-bottom: 1px solid #E4E5DA;
    }

    .profExpItemImgContainer1 {
        display: flex;
        width: 21px;
        height: 21px;
        padding: 10px 10px 9px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 7.386px;
        background: #FFF;
    }

    .profExpItemImg1 {
        width: 21px;
        height: 21px;
    }

    .profExpItemImgContainer2 {
        display: flex;
        width: 21px;
        height: 21px;
        padding: 10px 10px 9px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 7.386px;
        background: #FFF;
    }

    .profExpItemImg2 {
        width: 25.615px;
        height: 9px;
        flex-shrink: 0;
    }

    .profExpItemImgContainer3 {
        display: flex;
        width: 21px;
        height: 21px;
        padding: 10px 10px 9px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 7.386px;
        background: #FFF;
    }

    .profExpItemImg3 {
        width: 21px;
        height: 21px;
         background: url(../../../../assets/Images/profExpItemImg3.png) rgb(255, 255, 255) 50% / cover no-repeat;
    }

    .profExpText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
    }

    .profExpTitle {
        color: #111;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }

    .profExpDates {
        color: #707070;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 13px */
    }

    .mobileSeparator {

    }
}

@media screen and (min-width: 415px) and (max-width: 847px) {
    .entireExp {
        display: flex;
        justify-content: center;
        background: #F5F5F0;
    }

    .expContainer {
        display: flex;
        padding: 64px 16px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        width: 100%;
        max-width: 420px;
    }

    .profExp {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .profExpHeader {
        padding-bottom: 14px;
        color: #111;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 20px */
    }

    .profExpItems {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .profExpItem {
        display: flex;
        padding: 24px 0px;
        flex-direction: row;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-bottom: 1px solid #E4E5DA;
    }

    .profExpItemImgContainer1 {
        display: flex;
        width: 21px;
        height: 21px;
        padding: 10px 10px 9px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 7.386px;
        background: #FFF;
    }

    .profExpItemImg1 {
        width: 21px;
        height: 21px;
    }

    .profExpItemImgContainer2 {
        display: flex;
        width: 21px;
        height: 21px;
        padding: 10px 10px 9px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 7.386px;
        background: #FFF;
    }

    .profExpItemImg2 {
        width: 25.615px;
        height: 9px;
    }

    .profExpItemImgContainer3 {
        display: flex;
        width: 21px;
        height: 21px;
        padding: 10px 10px 9px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 7.386px;
        background: #FFF;
    }

    .profExpItemImg3 {
        width: 21px;
        height: 21px;
         background: url(../../../../assets/Images/profExpItemImg3.png) rgb(255, 255, 255) 50% / cover no-repeat;
    }

    .profExpText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
    }

    .profExpTitle {
        color: #111;
        font-family: Verdana;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }

    .profExpDates {
        color: #707070;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 13px */
    }

}

@media screen and (min-width: 848px) and (max-width: 1279px) {
    .entireExp {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        background: #F5F5F0;
    }

    .expContainer {
        display: flex;
        margin: 120px 80px;
        justify-content: space-between;
        align-self: stretch;
        max-width: 800px;
        width: 100%;
        gap: 112px;
    }

    .profExp {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .profExpHeader {
        color: #000;
        font-family: 'Montserrat';
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 28px */
        margin-bottom: 14px;
    }

    .profExpItems {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .profExpItem {
        display: flex;
        padding: 28px 0px;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-bottom: 1px solid #E4E5DA;
    }

    .profExpItemImgContainer1 {
        display: flex;
        padding: 12px 11px 9px 10px;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 10px;
    }

    .profExpItemImgContainer2 {
        display: flex;
        height: 52px;
        padding: 0px 6px 0px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #FFF;
    }

    .profExpItemImgContainer3 {
        display: flex;
        height: 52px;
        padding: 0px 6px 0px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #FFF;
    }

    .profExpItemImg1 {
        width: 31px;
        height: 31px;
    }

    .profExpItemImg2 {
        width: 37px;
        height: 13px;
    }

    .profExpItemImg3 {
        width: 30px;
        height: 30px;
    }


    .profExpText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
    }

    .profExpTitle {
        color: #000;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    .profExpDates {
        color: #707070;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 13px */
    }
}

@media screen and (min-width: 1280px) {
    .entireExp {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        background: #F5F5F0;
    }

    .expContainer {
        display: flex;
        margin: 120px 160px;
        justify-content: space-between;
        align-self: stretch;
        max-width: 880px;
        width: 100%;
        gap: 120px;
    }

    .profExp {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .profExpHeader {
        color: #000;
        font-family: 'Montserrat';
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 28px */
        margin-bottom: 14px;
    }

    .profExpItems {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .profExpItem {
        display: flex;
        padding: 28px 0px;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-bottom: 1px solid #E4E5DA;
    }

    .profExpItemImgContainer1 {
        display: flex;
        padding: 12px 11px 9px 10px;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 10px;
    }

    .profExpItemImgContainer2 {
        display: flex;
        height: 52px;
        padding: 0px 6px 0px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #FFF;
    }

    .profExpItemImgContainer3 {
        display: flex;
        height: 52px;
        padding: 0px 6px 0px 9px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #FFF;
    }

    .profExpItemImg1 {
        width: 31px;
        height: 31px;
    }

    .profExpItemImg2 {
        width: 37px;
        height: 13px;
    }

    .profExpItemImg3 {
        width: 30px;
        height: 30px;
    }


    .profExpText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
    }

    .profExpTitle {
        color: #000;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    .profExpDates {
        color: #707070;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 13px */
    }

}
