@media screen and (max-width: 414px) {
    .entireWork {
        display: flex;
        justify-content: center;
    }

    .workContentContainer {
        display: flex;
        flex-direction: column;
        padding: 0px 16px 64px 16px;
        width: 100%;
        max-width: 383px;
        align-items: center;
        justify-content: center;
    }

    .workTitle {
        align-self: stretch;
        color: #000;
        font-family: 'Montserrat';
        font-size: 24px;
        font-weight: 500;
        line-height: 100%; /* 24px */
        border-top: 1px solid #E4E5DA;
        padding-top: 40px;
    }

    .workContent {
        display: flex;
        padding: 32px 0px;
        width: 100%;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        border-bottom: 1px solid #E4E5DA;
    }

  
    .workContentImage {
        background-size: cover;
        background-position: center;
        display: flex;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .workContentImage:hover {
      opacity: .775
    }
    

    .workText1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        padding-top: 8px;
    }

    .workText1Title {
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: #111;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 26px */
    }

    .workText1Title:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .workText2 {
        align-self: stretch;
        color: #777;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 20.8px */
    }

    .workText3 {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }

    .workContentTags {
        display: flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #777;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
        border-radius: 10px;
        background: #F5F5F0;
    }

}

@media screen and (min-width: 415px) and (max-width: 847px) {
    .entireWork {
        display: flex;
        justify-content: center;
    }

    .workContentContainer {
        display: flex;
        flex-direction: column;
        margin: 0px 16px 64px 16px;
        width: 100%;
        max-width: 781px;
        height: auto;
        align-items: center;
        justify-content: center;
    }

    .workTitle {
        display: flex;
        align-items: flex-start;
        width: 100%;
        max-width: 680px;
        color: #000;
        font-family: 'Montserrat';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24px */
        border-top: 1px solid #E4E5DA;
        padding-top: 40px;
    }

    .workContent {
        display: flex;
        padding: 32px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        border-bottom: 1px solid #E4E5DA;
        width: 100%;
        max-width: 680px;
    }

    .workContentImage {
        background-size: contain;
        background-position: center;
        display: flex;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .workContentImage:hover {
      opacity: .775
    }

    .workText1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        padding-top: 8px;
    }

    .workText1Title {
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: #111;
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 26px */
    }

    .workText1Title:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .workText2 {
        align-self: stretch;
        color: #777;
        font-family: Verdana;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 20.8px */
    }

    .workText3 {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }

    .workContentTags {
        display: flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #777;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
        border-radius: 10px;
        background: #F5F5F0;
    }
}

@media screen and (min-width: 848px) and (max-width: 1279px) {

    .entireWork {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .workContentContainer {
        display: flex;
        flex-direction: column;
        margin: 76px 180px;
        width: 100%;
        max-width: 800px;
        height: auto;
   
    }

    .workTitle {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 781px;
        width: 100%;
        margin: 0px 32px;
        color: #000;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 32px */
        margin-bottom: 40px;
    }

    .workContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 16px;
        border-bottom: 1px solid #E4E5DA;
        margin-top: 32px;
        margin-left: 32px;
        margin-right: 32px;
    }


    .workContentImage {
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .workContentImage:hover {
      opacity: .775
    }

    .workText1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        color: #111;
        font-family: 'Montserrat';
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 28.6px */
        margin-top: 8px;
    }

    .workText1Title:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .workText2 {
        color: #777;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
    }

    .workText3 {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }

    .workContentTags {
        display: flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #F5F5F0;
        border: none;
        gap: 8px;
        color: #777;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
        margin-bottom: 32px;
    }
}

@media screen and (min-width: 1280px) {

    .entireWork {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .workContentContainer {
        display: flex;
        flex-direction: column;
        padding: 64px 0px 120px 0px;
        width: 100%;
        max-width: 880px;
        height: auto;
}

    .workTitle {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 880px;
        width: 100%;
        color: #000;
        font-family: 'Montserrat';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 32px */
        margin-bottom: 40px;
    }

    .workContentLink:hover {
      opacity: 0;
    }

    .workContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 16px;
        border-bottom: 1px solid #E4E5DA;
        padding: 32px 0px;
    }

    .workContentImage {
        background-position: center;
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    .workContentImage:hover {
      opacity: .775
    }

    .workText1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        color: #111;
        font-family: 'Montserrat';
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 28.6px */
        margin-top: 8px;
    }

    .workText1Title:hover {
        cursor: pointer;
        color: green;
        transition: 0.25s;
    }

    .workText2 {
        color: #777;
        font-family: Verdana;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
    }

    .workText3 {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }

    .workContentTags {
        display: flex;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #F5F5F0;
        border: none;
        gap: 8px;
        color: #777;
        font-family: Verdana;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
    }

}
